module.exports = [{
      plugin: require('/Users/sk/Programming/2stitch-webpage/page/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"linkImagesToOriginal":false,"sizeByPixelDensity":true,"quality":70,"withWebp":{"quality":80}},
    },{
      plugin: require('/Users/sk/Programming/2stitch-webpage/page/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.2stitch.com"},
    },{
      plugin: require('/Users/sk/Programming/2stitch-webpage/page/node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://analytics.codeandweb.com","siteUrl":"https://www.2stitch.com","disableCookies":true},
    },{
      plugin: require('/Users/sk/Programming/2stitch-webpage/page/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
